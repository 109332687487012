import { Box, Typography } from '@mui/material'
import QuizHeader from '../../common/QuizHeader'
import QuizContainer from '../../common/QuizContainer'
import { useQuizState } from '@providers/QuizProvider/QuizProvider.hooks'
import QuizStepBackgroundWrapper from '../../common/QuizStepBackgroundWrapper'
import QuizTimer from '@components/common/QuizTimer'
import { COGNIFI_QUIZ_QUESTIONS, QuizQuestions } from '../Quiz.constants'

function Question({
    questionNumber,
    noBackBtn,
    onLogoClick,
    stepsNumber = 20,
    quizQuestions: questions,
}: {
    questionNumber: number
    noBackBtn?: boolean
    onLogoClick?: () => void
    quizQuestions?: QuizQuestions
    stepsNumber?: number
}) {
    const { setStep, setQuizData, quizData } = useQuizState()

    const handleAnswerClick = (answer: string) => {
        setQuizData((prev) => ({
            ...prev,
            quizAnswers: {
                ...prev.quizAnswers,
                [questionNumber]: answer,
            },
        }))
        setTimeout(() => {
            setStep((prev) => prev + 1)
        }, 500)
    }

    const question = (questions ?? COGNIFI_QUIZ_QUESTIONS)[questionNumber - 1]

    return (
        <QuizStepBackgroundWrapper>
            <QuizHeader
                onBack={() => {
                    setStep((prev) => prev - 1)
                }}
                stepNumber={questionNumber}
                stepsNumber={stepsNumber}
                noBackBtn={noBackBtn}
                onLogoClick={onLogoClick}
            ></QuizHeader>
            <QuizContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        minHeight: '100%',
                        flexGrow: 1,
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: '24px',
                            background: '#fff',
                            boxShadow:
                                '0px 5px 20px 0px rgba(69, 50, 120, 0.05)',
                            padding: '20px',
                            marginTop: '20px',
                            img: {
                                width: '100%',
                            },
                        }}
                    >
                        <img src={question.image} alt="question"></img>
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            color: '#020202',
                            userSelect: 'none',
                            lineHeight: 'normal',
                            margin: '10px 0',
                            textAlign: 'center',
                        }}
                    >
                        Choose your answer:
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            gap: '10px 11px',
                        }}
                    >
                        {question.answers.map((answer) => {
                            const isSelected =
                                quizData.quizAnswers[questionNumber] === answer

                            return (
                                <Box
                                    key={answer}
                                    onClick={() => handleAnswerClick(answer)}
                                    sx={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        borderRadius: '16px',
                                        background: isSelected
                                            ? '#E5E1FC'
                                            : '#F3F2F8',
                                        transition: 'all 0.5s ease',
                                        width: '100%',
                                        maxWidth: 'calc(50% - 10px)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '15px',
                                        WebkitTapHighlightColor: 'transparent',
                                        img: {
                                            width: '70px',
                                            height: '70px',
                                        },
                                    }}
                                >
                                    <img src={answer} alt="answer"></img>
                                    <Box
                                        sx={{
                                            background: '#F3F2F8',
                                            transition: 'all 0.5s ease',
                                            width: '100%',
                                            height: '100%',
                                            position: 'absolute',
                                            opacity:
                                                !isSelected &&
                                                quizData.quizAnswers[
                                                    questionNumber
                                                ] !== null
                                                    ? 0.5
                                                    : 0,
                                        }}
                                    ></Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            </QuizContainer>
            <Box
                sx={{
                    maxWidth: '480px',
                    width: '100%',
                    margin: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        height: '28px',
                        margin: '20px 20px 36px',
                    }}
                >
                    <Box
                        sx={{
                            height: '1px',
                            backgroundColor: '#E6E7EB',
                            borderRadius: '16px',
                            width: '100%',
                        }}
                    ></Box>

                    <QuizTimer />

                    <Typography
                        onClick={() => {
                            setStep((prev) => prev + 1)
                        }}
                        sx={{
                            WebkitTapHighlightColor: 'transparent',
                            cursor: 'pointer',
                            background: '#fff',
                            paddingLeft: '10px',
                            position: 'absolute',
                            right: '0px',
                            fontFamily: 'Avenir400',
                            fontSize: '16px',
                            color: 'rgba(2, 2, 2, 0.50)',
                            userSelect: 'none',
                            display: 'flex',
                            gap: '3px',
                            alignItems: 'center',
                        }}
                    >
                        Skip
                        <img
                            src={'/cognifi/svg/chevron.svg'}
                            alt="chevron"
                        ></img>
                    </Typography>
                </Box>
            </Box>
        </QuizStepBackgroundWrapper>
    )
}

export default Question
